import React, { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import './ScrollingSection.css';
import videoSrc from '../../assets/videos/loop1.mp4';
import videoSrc2 from '../../assets/videos/loop2.mp4';
import videoSrc3 from '../../assets/videos/loop4.mp4';
import globeGif from '../../assets/images/globe.gif';

const useIntersectionObserver = (setIsVisible) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { root: null, rootMargin: '0px 0px -200px 0px', threshold: 0.1 } // Adjust the rootMargin and threshold
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [setIsVisible]);

  return ref;
};

const ScrollingSection = () => {
  const [isMarqueeVisible, setIsMarqueeVisible] = useState(false);
  const [isPhilosophyVisible, setIsPhilosophyVisible] = useState(false);

  const marqueeRef = useIntersectionObserver(setIsMarqueeVisible);
  const philosophyRef = useIntersectionObserver(setIsPhilosophyVisible);

  return (
    <div>
      <div
        id="marquee"
        className={`marquee-container ${isMarqueeVisible ? 'visible' : ''}`}
      >
        <Marquee direction='left' ref={marqueeRef}>
          <h2><span className='fancy-font'>CREATIVE</span><br />DESIGN</h2>
          <div className='marquee-video'>
            <video playsInline src={videoSrc} webkit-playsInline autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} />
          </div>
          <h2><span className='fancy-font'>CUSTOM</span><br />DEVELOPMENT</h2>
          <div className='marquee-video'>
            <video playsInline src={videoSrc2} webkit-playsInline autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} />
          </div>
          <h2><span className='fancy-font'>BRAND</span><br />IDENTITIES</h2>
          <div className='marquee-video'>
            <video playsInline src={videoSrc3} webkit-playsInline autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 'inherit' }} />
          </div>
        </Marquee>
        <div className={`line ${isPhilosophyVisible ? 'visible' : ''}`}></div>
        
      <div
        className={`philosophy ${isPhilosophyVisible ? 'visible' : ''}`}
        ref={philosophyRef}
      >
        
        <div className='philosophy-left'>
          <img src={globeGif} alt="animated spinning globe" />
        </div>
        <div className='philosophy-right'>
          <h3>My Philosophy</h3>
          <p>The web doesn't need to be boring and I don't like cookie-cutter solutions. Embracing unique, user-centered design enhances usability, boosts engagement, and fosters innovation in web development.</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ScrollingSection;
