import React from 'react';
import Marquee from 'react-fast-marquee';
import './TechStack.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCss3Alt, faHtml5, faJs, faNode, faReact, faFigma, faPython, faPhp } from '@fortawesome/free-brands-svg-icons';


const TechStack = () => {
  return (
    <section id="techStack">
      <Marquee direction='right' speed="20" gradient="true" gradientColor='black'>
        <FontAwesomeIcon icon={faHtml5} color='white'  className='fa-2x'/>
        <FontAwesomeIcon icon={faCss3Alt} color='white'  className='fa-2x'/>
        <FontAwesomeIcon icon={faJs} color='white'  className='fa-2x'/>
        <FontAwesomeIcon icon={faReact} color='white'  className='fa-2x'/>
        <FontAwesomeIcon icon={faNode} color='white' className='fa-2x'/>
        <FontAwesomeIcon icon={faFigma} color='white' className='fa-2x'/>
        <FontAwesomeIcon icon={faPython} color='white' className='fa-2x'/>
        <FontAwesomeIcon icon={faPhp} color='white' className='fa-2x'/>

      </Marquee>
    </section>
  );
};

export default TechStack;
