import React, { useEffect, useState } from 'react';
import './Stars.css'

const Stars = () => {
  const [starsProperties, setStarsProperties] = useState([]);

  const starColors = ['#fff4d1', '#4432FF', '#1B62B0']

  useEffect(() => {
    const calculateStarsProperties = () => {
      const screenWidth = window.innerWidth;
      const stars = Math.floor(screenWidth / 40); // Adjust as needed
      const starsArray = Array.from({ length: stars }, (_, index) => ({
        key: index,
        width: `${Math.floor(Math.random() * 3) + 1}`,
        animationDuration: `${Math.random() * 10 + 5}s`,
        translationX: `${Math.random() * 50 - 25}px`,
        translationY: `${Math.random() * 50 - 25}px`,
        animationDelay: `${Math.random() * 3 + 1}s`,
        blurred: Math.random() < 0.15,
        starColor: Math.random() <= 0.7 ? 1 : Math.random() <= 0.85 ? 2 : 3,
        starType: Math.random() <= 0.8 ? 1 : 2,

      }));
      setStarsProperties(starsArray);
    };

    calculateStarsProperties();
  }, []);

  const renderStars = () => {
    return starsProperties.map((star) => {
      const starColor = starColors[star.starColor - 1]
      switch (star.starType) {
        case 1:
          return (
            <span
              key={star.key}
              className='star'
              style={{
                width: `${star.width}px`,
                height: `${star.width}px`,
                transform: `translate(${star.translationX}, ${star.translationY})`,
                filter: star.blurred ? 'blur(2px)' : 'none',
                display: 'inline-block',
                position: 'absolute',
                top: `${Math.random() * 100}%`,
                right: `${Math.random() * 100}%`,
                backgroundColor: starColor,
                borderRadius: '50%',
                animation: `animateStar ${star.animationDuration} linear infinite`,
                animationDelay: star.animationDelay,
                opacity: 0,
                boxShadow: `0 0 20px 1px #fff`,
              }}
            ></span>
          );
        case 2:
          return (
            <span
              key={star.key}
              className='star'
              style={{
                width: `${star.width * 3}px`,
                height: `${star.width * 3}px`,
                transform: `translate(${star.translationX}, ${star.translationY})`,
                filter: star.blurred ? 'blur(2px)' : 'none',
                display: 'inline-block',
                position: 'absolute',
                top: `${Math.random() * 100}%`,
                right: `${Math.random() * 100}%`,
                animation: `animateStar ${star.animationDuration} linear infinite`,
                animationDelay: star.animationDelay,
                opacity: 0,

              }}
            >
              <span className='vertStar'
                style={{
                  width: `${star.width * 3}px`,
                  height: `${star.width}px`,
                  backgroundColor: starColor,
                  display: 'inline-block',
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)',
                  top: '50%',
                  left: '50%',
                  borderRadius: '5px',
                  opacity: 0.5,
                }}
              ></span>
              <span className='horStar'
                style={{
                  width: `${star.width}px`,
                  height: `${star.width * 3}px`,
                  backgroundColor: starColor,
                  display: 'inline-block',
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)',
                  top: '50%',
                  left: '50%',
                  borderRadius: '5px',
                  opacity: 0.5,
                }}

              ></span>

              <span className='starCore'
                style={{
                  width: `${star.width * 1.5}px`,
                  height: `${star.width * 1.5}px`,
                  transform: `translate(-50%, -50%)`,
                  filter: star.blurred ? 'blur(2px)' : 'none',
                  display: 'inline-block',
                  position: 'absolute',
                  top: `50%`,
                  left: `50%`,
                  backgroundColor: starColor,
                  borderRadius: '50%',
                  boxShadow: `0 0 20px 1px #fff`,
                  opacity: 0.25,
                }}

              ></span>
            </span>
          );
        default:
          return null; // Adjust this based on your requirements for other cases
      }
    });
  };



  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        zIndex: 1,
        pointerEvents: 'none'
      }}
    >
      {renderStars()}
    </div>
  );
};

export default Stars;
