import React, { useState, useEffect } from 'react';
import './Header.css';
import HeaderText from './HeaderText/HeaderText';
import GradientBG from './GradientBG/GradientBG';

const Header = (scrollPosition) => {

  const headerOpacity = 1 - scrollPosition / 600; 
  const maxScrollParallax = 50;
  const scrollParallax = Math.min(maxScrollParallax, 0 + scrollPosition / 20);

  const scrollToNextSection = () => {
    const nextSection = document.getElementById('about');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header id="hero" style={{ opacity: headerOpacity, pointerEvents: headerOpacity < 0.5 ? 'none' : 'auto' }}>
      <section id="home">
      <div className='headerWrapperBorder' style={{ top: `${scrollParallax}px` }}>
        <div className='headerWrapper'>
          <div className='headerGradient'>
            <GradientBG />
          </div>
          <HeaderText />
        </div>
      </div>
      <div className='arrowDown' onClick={scrollToNextSection}>
        &#x2193; 
      </div>
      </section>
    </header>
  );
}

export default Header;
