import React from 'react'
import transition from '../../transition'
import ProjectTitle from '../ProjectTitle/ProjectTitle'
import ProjectInfo from '../ProjectInfo/ProjectInfo'
import '../Project.css'
import ProjectVideo from '../ProjectVideo/ProjectVideo'
import ProjectFeatures from '../ProjectFeatures/ProjectFeatures'
import ProjectGallery from '../ProjectGallery/ProjectGallery'

import desktop2 from '../../assets/images/projects/igws/desktop2.png';
import desktop3 from '../../assets/images/projects/igws/desktop3.png';
import desktop4 from '../../assets/images/projects/igws/desktop4.png';
import desktop5 from '../../assets/images/projects/igws/desktop5.png';
import desktop6 from '../../assets/images/projects/igws/desktop6.png';
import desktop7 from '../../assets/images/projects/igws/desktop7.png';
import desktop8 from '../../assets/images/projects/igws/desktop8.png';
import desktop9 from '../../assets/images/projects/igws/desktop9.png';
import desktop10 from '../../assets/images/projects/igws/desktop10.png';
import desktop11 from '../../assets/images/projects/igws/desktop11.png';
import desktop12 from '../../assets/images/projects/igws/desktop12.png';
import desktop13 from '../../assets/images/projects/igws/desktop13.png';
import desktop14 from '../../assets/images/projects/igws/desktop14.png';
import desktop15 from '../../assets/images/projects/igws/desktop15.png';
import desktop16 from '../../assets/images/projects/igws/desktop16.png';
import desktop17 from '../../assets/images/projects/igws/desktop17.png';
import desktop18 from '../../assets/images/projects/igws/desktop18.png';

import phone1 from '../../assets/images/projects/igws/phone1.png';
import phone2 from '../../assets/images/projects/igws/phone2.png';
import phone3 from '../../assets/images/projects/igws/phone3.png';
import phone4 from '../../assets/images/projects/igws/phone4.png';
import phone5 from '../../assets/images/projects/igws/phone5.png';
import phone6 from '../../assets/images/projects/igws/phone6.png';
import phone7 from '../../assets/images/projects/igws/phone7.png';
import phone8 from '../../assets/images/projects/igws/phone8.png';

import logo from '../../assets/svg/IGWSLogo.svg';

import Banner from '../../assets/images/projects/igws/banner.png';
import ProjectNextButton from '../ProjectNextButton/ProjectNextButton'
import { Parallax } from 'react-parallax'
import ProjectParallax from '../ProjectParallax/ProjectParallax'

const IGWS = () => {
  const color = "var(--igws)"
  const images1 = [
    { src: phone5, alt: 'IGWS homepage mobile' },
    { src: desktop18, alt: 'IGWS homepage' },
    { src: desktop15, alt: 'Standard CMS page' },
  ];

  const images2 = [
    { src: phone1, alt: 'CMS card page on mobile' },
    { src: desktop14, alt: 'Searchable card CMS page' },
    { src: desktop12, alt: 'CMS Page featuring card links' },
  ];

  const images3 = [
    { src: phone2, alt: 'Digital collections api search on mobile' },
    { src: desktop11, alt: 'Advanced search feature' },
    { src: desktop10, alt: 'Users can search through active and completed research projects' },
  ];

  const images4 = [
    { src: phone4, alt: 'Mobile news article' },
    { src: desktop13, alt: 'Staff profile screen' },
    { src: desktop9, alt: 'Staff profile editor' },
  ];

  const images5 = [
    { src: phone7, alt: 'Admin CMS editor mobile' },
    { src: desktop8, alt: 'Admin CMS editor' },
    { src: desktop6, alt: 'Admin news editor' },
  ];

  const images6 = [
    { src: phone6, alt: 'Mobile CMS page' },
    { src: desktop5, alt: 'Public alert editor with alert at top of screen' },
    { src: desktop4, alt: 'Hero editor' },
  ];

  const images7 = [
    { src: phone3, alt: 'Mobile CMS page with cards' },
    { src: desktop3, alt: 'Jobs editor' },
    { src: desktop2, alt: 'User permissions editor' },
  ];

  const images8 = [
    { src: phone8, alt: 'Mobile environmental assessment' },
    { src: desktop17, alt: 'Environmental assessment' },
    { src: desktop16, alt: 'Environmental assessment map plotter' },
  ];

  return (
    <div className='project igws'>
      <div className='projectWrapper'>
        <ProjectTitle title="IGWS" color={color} />
        <ProjectInfo date="July 2022 - Present"
          stack={["React.js", "Node.js", "ArcGIS", "MySQL"]}
          buttons={[{link: "https://igws.indiana.edu/", words: "View Site"}]}
          color={color}
        />
        <p>As the sole developer driving the Indiana Geological and Water Survey (IGWS) website overhaul, I seamlessly transitioned from an outdated ColdFusion system to a modern architecture, integrating React, Node.js, and MySQL. Overcoming challenges from the 15-year-old system, this transformation elevated the site's speed, performance, and adaptability, positioning IGWS as a national leader in geologic survey platforms.</p>
        <p>A pivotal aspect of the revamp was the bespoke Content Management System (CMS) tailored for IGWS staff. This intuitive interface empowers regular biweekly meetings with various staff in the Info Services department to determine evolving site needs. Achieving a notable 99.96% reduction in website size and establishing a new server infrastructure further solidified the project's success, making IGWS the fastest geologic survey platform with top accessibility scores nationwide.</p>
        <p>Beyond the technical accomplishments, I prioritized the visual and user experience, implementing a modified IU Rivet design system. This adaptation caters specifically to IGWS goals, aligning with university design principles while ensuring inclusivity. The integration of this modified design system reflects my commitment to a comprehensive, user-centric platform, effectively balancing aesthetics with functionality.</p>
        <p className='project-bold-text'>Independently built, the IGWS site, with a custom CMS, leads national geologic surveys, drawing <span className='bold'>half a million annual viewers</span>.</p>

        <ProjectParallax background={Banner} image={logo} />

        <ProjectFeatures featureList={["Google/ IU Login", "Custom CMS", "Live Map Viewer", "Data Visualization Tools", "User Permissions", "Custom Banners", "News Articles", "Advanced Search Algorithm", "Research Project Uploading", "Custom API tools", "Enviornmental Assessment Tool"]} color={color}/>
        <h2 className='screenshots'>Screenshots<span style={{color: color}}>.</span></h2>
        <ProjectGallery images={images1} reverse={false} />
        <ProjectGallery images={images2} reverse={true} />
        <ProjectGallery images={images3} reverse={false} />
        <ProjectGallery images={images4} reverse={true} />
        <ProjectGallery images={images5} reverse={false} />
        <ProjectGallery images={images6} reverse={true} />
        <ProjectGallery images={images7} reverse={false} />
        <ProjectGallery images={images8} reverse={true} />
        <ProjectNextButton link="/projects/menumaker" color={color}/>

      </div>
    </div>
  )
}

export default transition(IGWS)