import React from 'react'
import transition from '../../transition'
import ProjectTitle from '../ProjectTitle/ProjectTitle'
import ProjectInfo from '../ProjectInfo/ProjectInfo'
import '../Project.css'
import ProjectVideo from '../ProjectVideo/ProjectVideo'
import ProjectFeatures from '../ProjectFeatures/ProjectFeatures'
import ProjectGallery from '../ProjectGallery/ProjectGallery'

import CrimsonFlagsImage1 from '../../assets/images/projects/crimson-flags/crimsonflags1.png'
import CrimsonFlagsImage2 from '../../assets/images/projects/crimson-flags/crimsonflags2.png';
import CrimsonFlagsImage3 from '../../assets/images/projects/crimson-flags/crimsonflags3.png';
import CrimsonFlagsImage4 from '../../assets/images/projects/crimson-flags/crimsonflags4.png';
import CrimsonFlagsImage5 from '../../assets/images/projects/crimson-flags/crimsonflags5.png';
import CrimsonFlagsImage6 from '../../assets/images/projects/crimson-flags/crimsonflags6.png';
import CrimsonFlagsImage7 from '../../assets/images/projects/crimson-flags/crimsonflags7.png';
import CrimsonFlagsImage8 from '../../assets/images/projects/crimson-flags/crimsonflags8.png';
import CrimsonFlagsImage9 from '../../assets/images/projects/crimson-flags/crimsonflags9.png';
import ProjectNextButton from '../ProjectNextButton/ProjectNextButton'

const CrimsonFlags = () => {
  const color = "var(--crimsonFlags)"
  const images1 = [
    { src: CrimsonFlagsImage9, alt: 'Admin quiz editor' },
    { src: CrimsonFlagsImage1, alt: 'Section of landing page' },
    { src: CrimsonFlagsImage2, alt: 'Google Maps integration for apartment viewing' },
  ];
  const images2 = [
    { src: CrimsonFlagsImage8, alt: 'Notifications' },
    { src: CrimsonFlagsImage3, alt: 'User compatibility quiz used for matching' },
    { src: CrimsonFlagsImage4, alt: 'Hovering checkmark on verified profile' },
  ];
  const images3 = [
    { src: CrimsonFlagsImage7, alt: 'Mobile chat' },
    { src: CrimsonFlagsImage5, alt: 'Users can chat with matches' },
    { src: CrimsonFlagsImage6, alt: 'Matching algorithm displays top 3 users based on quiz answers' },
  ];
  return (
    <div className='project crimsonFlags'>
      <div className='projectWrapper'>
        <ProjectTitle title="Crimson Flags" color={color} />
        <ProjectInfo date="August 2021 - May 2022"
          stack={["PHP", "MySQL", "JavaScript", "HTML/CSS", "Figma", "Git/Github"]}
          buttons={[{link: "https://zion.luddy.indiana.edu/info-capstone-2022/crimson-flags", words: "More Info"}]}
          color={color}
        />
        <p>In the Indiana University Informatics I494 Capstone Class, teams of students were tasked with designing and developing an information system which solves a 'real-world' problem IU students face. My team's system aimed to build a roommate matching web application for IU students that integrates user verification and a personality/lifestyle quiz to match users based on interest.</p>
        <p>Crimson Flags was built over the course of two semesters by me and group of 3 other students, each with a different skillset. The first semester was dedicated towards planning (concept, design, database structure, user testing, and ethical implications), while the second semester was reserved for implemenation (database creation, dummy data creation, web-programming, and deployment). Groups were required to use PHP and not permitted to use any form of framework (React, Bootstrap, Tailwind, Etc). Having the largest background in web development, I took on the role of project lead and had a hand in nearly every feature implemented into the web app.</p>
        <p className='project-bold-text'>Crimson Flags ended up winning numerous awards from the Luddy School of Engineering and Computing, including the <span className='bold'>Top Project Award</span>.</p>
        <ProjectVideo video="https://www.youtube.com/embed/M3-SDItZ8XQ?si=bzAaffmu21dML6Cp"/>
        <ProjectFeatures featureList={["Google Login", "Google Maps Apartment Viewer", "Personality Quiz with 'Crimson Flag' Feature", "Matching Algorithm", "Edit/ Delete Profile", "User Verification", "Review Match", "Notifications", "User Permissions", "Accept/ Deny Match", "Unmatch User", "Chat Messaging", "Admin Features: (Edit Quiz, Approve/ Deny Verification Requests, View Reports, Ban Users, Promote/ Demote Users to Admin/ Basic User)", "Match with Email/ Phone Number"]} color={color}/>
        <h2 className='screenshots'>Screenshots<span style={{color: 'var(--crimsonFlags)'}}>.</span></h2>
        <ProjectGallery images={images1} reverse={false} />
        <ProjectGallery images={images2} reverse={true} />
        <ProjectGallery images={images3} reverse={false} />
        <ProjectNextButton link="/projects/qfwellness" color={color}/>

      </div>
    </div>
  )
}

export default transition(CrimsonFlags)