import React from 'react'
import transition from '../../transition'
import ProjectTitle from '../ProjectTitle/ProjectTitle'
import ProjectInfo from '../ProjectInfo/ProjectInfo'
import '../Project.css'
import ProjectVideo from '../ProjectVideo/ProjectVideo'
import ProjectFeatures from '../ProjectFeatures/ProjectFeatures'
import ProjectGallery from '../ProjectGallery/ProjectGallery'

import MenuMakerImage1 from '../../assets/images/projects/menu-maker/menu-maker-login.png'
import MenuMakerImage2 from '../../assets/images/projects/menu-maker/menu-maker-dashboard.png';
import MenuMakerImage3 from '../../assets/images/projects/menu-maker/menu-maker-editor-large.png';
import MenuMakerImage4 from '../../assets/images/projects/menu-maker/menu-maker-display.jpg';
import MenuMakerImage5 from '../../assets/images/projects/menu-maker/menu-maker-christmas.png';
import MenuMakerImage6 from '../../assets/images/projects/menu-maker/menu-maker-options.png';
import MenuMakerImage7 from '../../assets/images/projects/menu-maker/menu-maker-preview.png';
import MenuMakerImage8 from '../../assets/images/projects/menu-maker/menu-maker-scale.png';
import MenuMakerImage9 from '../../assets/images/projects/menu-maker/menu-maker-editor.png';
import ProjectNextButton from '../ProjectNextButton/ProjectNextButton'

const MenuMaker = () => {
  const color = "var(--menuMaker)"
  const images1 = [
    { src: MenuMakerImage1, alt: 'Menu Maker login screen' },
    { src: MenuMakerImage3, alt: 'Customize items, add titles, change colors, and more' },
    { src: MenuMakerImage4, alt: "Boards on display in BuffaLouie's" },
  ];
  const images2 = [
    { src: MenuMakerImage9, alt: 'Editor scales to mobile view' },
    { src: MenuMakerImage2, alt: 'Dashboard' },
    { src: MenuMakerImage8, alt: 'Menu scales with screen size' },
  ];
  const images3 = [
    { src: MenuMakerImage7, alt: 'Preview menu boards from mobile' },
    { src: MenuMakerImage6, alt: 'Different options for customizing a board' },
    { src: MenuMakerImage5, alt: 'Supports image uploads and has a "festive mode" in which a snow animation is added' },
  ];
  return (
    <div className='project menuMaker'>
      <div className='projectWrapper'>
        <ProjectTitle title="Menu Maker" color={color} />
        <ProjectInfo date="January 2022"
          stack={["PHP", "MySQL", "JavaScript", "HTML/CSS", "Git/Github"]}
          buttons={[{link: "https://kevinfuller.me/projects/menu-maker/login.php", words: "Demo Site"}]}
          color={color}
        />
        <p>Menu Maker is a web application built to assist in the live updating of digital signage boards. Originally built for BuffaLouie's, a wing restaraunt in Bloomington Indiana, I eventually expanded on the functionality to the point where many different restaraunts around Bloomington utilize it to keep their menu boards updated.</p>
        <p>I built Menu Maker while I was a manager at BuffaLouie's during my undergrad. Originally, BuffaLouie's used static images designed in Illustrator uploaded to a computer stick. However, management was not familiar with Illustrator and had a difficult time making changes to the images without my direct assistance. Additionally, the process of uploading images to the intel sticks proved to be tedious in the fast paced restaraunt environment.</p>
        <p>After browsing other digital signage sollutions, I found that the software availible was either outdated or behind a steep paywall that wasn't ideal for our small business. Menu Maker was made over the course of 2 weeks and I gradually iterated upon it when new features came to mind. I took a mobile first approach towards the website to allow for easy updating from management's smartphones.</p>
        <p className='project-bold-text'>Menu Maker is currently used by <span className='bold'>Five Different Small Businesses</span> around Bloomington Indiana.</p>
        <ProjectVideo video="https://www.youtube.com/embed/FPQaCRg4JP8?si=pddoB_0ET5AbWGqq"/>
        <ProjectFeatures featureList={["Login System", "Individual Accounts", "Create/ Edit/ Delete Menu Boards", "Add/ Edit/ Delete Items", "Add Headers, Subtitles, and Tables", "Change Accent Color of Individual Board", "Change Board Title", "Upload Background/ Foreground Pictures", "Festive Mode for the Holidays", "Generate Unique Link for Boards", "Make Items In/ Out of Stock"]} color={color}/>
        <h2 className='screenshots'>Screenshots<span style={{color: color}}>.</span></h2>
        <ProjectGallery images={images1} reverse={false} />
        <ProjectGallery images={images2} reverse={true} />
        <ProjectGallery images={images3} reverse={false} />
        <ProjectNextButton link="/projects/crimsonflags" color={color}/>

      </div>
    </div>
  )
}

export default transition(MenuMaker)