import React, { useState, useEffect, useCallback, useRef } from 'react';
import './GradientBG.css';

const GradientBG = () => {
    const [interactiveSettings, setInteractiveSettings] = useState('');
    const curX = useRef(0);
    const curY = useRef(0);
    const tgX = useRef(0);
    const tgY = useRef(0);
    const animationFrameId = useRef(null);

    const handleMouseMove = useCallback((e) => {
        // Get the container element
        const container = document.getElementById('gradient-bg');
    
        // Get the container's position on the page
        const containerRect = container.getBoundingClientRect();
    
        // Calculate the relative x and y positions
        const relativeX = e.clientX - containerRect.left;
        const relativeY = e.clientY - containerRect.top;
    
        // Update target positions
        tgX.current = relativeX;
        tgY.current = relativeY;
    }, []);

    const move = useCallback(() => {
        curX.current += (tgX.current - curX.current) / 20;
        curY.current += (tgY.current - curY.current) / 20;
        setInteractiveSettings(`translate(${Math.round(curX.current)}px, ${Math.round(curY.current)}px)`);
        animationFrameId.current = requestAnimationFrame(move);
    }, []);

    useEffect(() => {
        animationFrameId.current = requestAnimationFrame(move);
        return () => cancelAnimationFrame(animationFrameId.current);
    }, [move]);

    return (
        <div className='gradient-bg' id="gradient-bg" onMouseMove={handleMouseMove}>
            <div className='gradient-black-overlay'></div>
            <svg xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
                        <feBlend in="SourceGraphic" in2="goo" />
                    </filter>
                </defs>
            </svg>
            <div className='gradients-container'>
                <div className="g1"></div>
                <div className="g2"></div>
                <div className="g3"></div>
                <div className="g4"></div>
                <div className="g5"></div>
                <div className="interactive" style={{ transform: interactiveSettings }}></div>
            </div>
        </div>
    );
};

export default GradientBG;
