import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/Home/Home'
import Navigation from './components/Navigation/Navigation';
import Stars from './pages/Home/Header/Stars/Stars';
import CrimsonFlags from './Projects/CrimsonFlags/CrimsonFlags';
import { AnimatePresence } from 'framer-motion';
import Footer from './components/Footer/Footer';
import ScrollToTop from './ScrollToTop';
import MenuMaker from './Projects/MenuMaker/MenuMaker';
import IGWS from './Projects/IGWS/IGWS';
import Binti from './Projects/Binti/Binti';
import ElectricFox from './Projects/ElectricFox/ElectricFox';
import Resume from './Projects/Resume/Resume';
import QFWellness from './Projects/QFWellness/QFWellness';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

function App() {

  ReactGA.initialize('G-L3S365D76J');

  const location = useLocation();
  
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (

    <div className="App" id="App">
      <Stars />
      <Navigation />
        <AnimatePresence mode="wait">
          
          <Routes location={location} key={location.pathname}>
          <Route
            path="/*"
            element={
              <>
                <ScrollToTop />
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/projects/crimsonflags" element={<CrimsonFlags />} />
                  <Route path="/projects/menumaker" element={<MenuMaker />} />
                  <Route path="/projects/igws" element={<IGWS />} />
                  <Route path="/projects/bintibiz" element={<Binti />} />
                  <Route path="/projects/electricfox" element={<ElectricFox />} />
                  <Route path="/resume" element={<Resume />} />
                  <Route path="/projects/qfwellness" element={<QFWellness />} />

                </Routes>
              </>
            }
          />
          </Routes>
        </AnimatePresence>
        <Footer />
    </div>

  );
}

export default App;
