import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import vertexShader from './shaders/vertex.js';
import fragmentShader from './shaders/fragment.js';
import './style.css';

extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass });

const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

const Torus = React.memo(({ torusRef, mousePosition, detail }) => {
  const { viewport } = useThree();

  useFrame((state, delta) => {
    const elapsedTime = state.clock.getElapsedTime();
    if (torusRef.current) {
      const material = torusRef.current.material;
      material.uniforms.uTime.value = elapsedTime;
      material.uniforms.uResolution.value.set(viewport.width, viewport.height);
      torusRef.current.rotation.z += 0.1 * delta;

      const mouseX = (mousePosition.current.x / window.innerWidth) * 2 - 1;
      const mouseY = -(mousePosition.current.y / window.innerHeight) * 2 + 1;

      const added = Math.abs(Math.abs(mouseX) + Math.abs(mouseY));
      const distance = Math.min(Math.max(added * 15, 3), 15);
      const spread = Math.min(Math.max(added * 2.5, 0.5), 1.3);

      material.uniforms.uNoise.value += (distance - material.uniforms.uNoise.value) * 0.05;
      material.uniforms.uSpread.value += (spread - material.uniforms.uSpread.value) * 0.05;
    }
  });

  return (
    <mesh ref={torusRef}>
      <torusGeometry args={[1, 0.3, detail, detail]} />
      <shaderMaterial
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        side={THREE.DoubleSide}
        uniforms={{
          uTime: { value: 0 },
          uResolution: { value: new THREE.Vector2(viewport.width, viewport.height) },
          uDisplace: { value: 1 },
          uSpread: { value: 1 },
          uNoise: { value: 15 },
        }}
      />
    </mesh>
  );
});

const Effects = React.memo(() => {
  const { gl, scene, camera } = useThree();
  const composer = useRef();

  useEffect(() => {
    const effectComposer = new EffectComposer(gl);
    effectComposer.addPass(new RenderPass(scene, camera));
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(150, 150), 9, 0.3, 0.001);
    effectComposer.addPass(bloomPass);
    composer.current = effectComposer;
  }, [gl, scene, camera]);

  useFrame(() => {
    if (composer.current) composer.current.render();
  }, 1);

  return null;
});

const Scene = React.memo(({ torusRef, mousePosition, detail }) => {
  return (
    <>
      <Torus torusRef={torusRef} mousePosition={mousePosition} detail={detail} />
      <Effects />
    </>
  );
});

const App = () => {
  const torusRef = useRef();
  const mousePosition = useRef({ x: 0, y: 0 });
  const [isRendered, setIsRendered] = useState(!isMobile());
  const detail = isMobile() ? 50 : 150;

  const handleMouseMove = useCallback((event) => {
    mousePosition.current = { x: event.clientX, y: event.clientY };
  }, []);

  useEffect(() => {
    if (isRendered) {
      window.addEventListener('mousemove', handleMouseMove, { passive: true });
      const handleResize = () => {
        // Dispose of existing Three.js objects
        if (torusRef.current) {
          const material = torusRef.current.material;
          if (material) {
            material.dispose();
          }
          torusRef.current.geometry.dispose();
        }

        // Trigger re-render by toggling state or forceUpdate if needed
        setIsRendered(false);
        setTimeout(() => setIsRendered(true), 0);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [handleMouseMove, isRendered]);

  return isRendered ? (
    <div className='blackholeWrapper'>
      <div className='blackholeGradientOverlay'></div>
      <Canvas className="blackhole" camera={{ position: [0, 0, 2] }} resize={{ scroll: false }}>
        <Scene torusRef={torusRef} mousePosition={mousePosition} detail={detail} />
      </Canvas>
    </div>
  ) : null;
};

export default App;
