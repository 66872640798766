import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Temporarily disable smooth scrolling
    const originalScrollBehavior = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = 'auto';

    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Re-enable smooth scrolling after scrolling to the top
    document.documentElement.style.scrollBehavior = originalScrollBehavior;
  }, [pathname]);

  return null;
};

export default ScrollToTop;
