import React, { useState, useEffect } from 'react';
import './HeaderText.css';
import Moon from '../../../../assets/svg/Moon';
import EarthFront from '../../../../assets/svg/EarthFront';
import EarthBack from '../../../../assets/svg/EarthBack';
import { Link } from 'react-router-dom';

const HeaderText = () => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsActive(true);
        }, 500);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
        <div className='headerTextWrapper'>
            <div className={`headerText ${isActive ? 'active' : ''}`}>
                <h1>
                    <span className='headerWord' style={{ transitionDelay: '0ms' }}>Hello, </span> 
                    <span className='headerWord' style={{ transitionDelay: '50ms' }}>I'm</span>
                </h1>
                <h1>
                    <span id="name" className='projectTextHeadingFancy'>
                        <span className='headerWord' style={{ transitionDelay: '300ms' }}>Kev</span>
                        <span className='headerWord' style={{ transitionDelay: '350ms' }}>in</span><br />
                        
                        <span className='headerWord' style={{ transitionDelay: '500ms' }}>Full</span><span className='headerWord' style={{ transitionDelay: '550ms' }}>er</span>
                        </span></h1>
                <h2><span className='headerWord ' style={{ transitionDelay: '900ms' }}>Fullstack</span> <span className='headerWord' style={{ transitionDelay: '950ms' }}>Web </span><span className='headerWord' style={{ transitionDelay: '1000ms' }}>Developer</span></h2>
                <p>
                    {"Turning Ideas into a Universe of Digital Possibilities.".split(" ").map((word, index) => (
                        <span key={index} className='headerWord' style={{ transitionDelay: `${index * 40 + 1000}ms` }}>{word} </span>
                    ))}

                </p>
                <div className='flex'>
                    <Link to="/resume" className='resumeButton headerWord'  style={{ transitionDelay: `${1440}ms` }}>
                        Resume
                    </Link>
                   <Link to="#contact" className='contactButton headerWord'  style={{ transitionDelay: `${1480}ms` }}>Contact</Link>
                </div>
            </div>

            <div  className={`earthGraphic ${isActive ? 'active' : ''}`}>
                <div className="planetContainer">
                    <div className="earth">
                        <EarthFront />
                        <EarthBack />
                    </div>
                    <Moon />
                </div>
            </div>
        </div>
    );
};

export default HeaderText;
