import React from 'react'
import { Parallax } from 'react-parallax'

const ProjectParallax = (props) => {
    return (
        <Parallax bgImage={props.background} strength={100} style={{ margin: '25px 0' }} >
            <div style={{  display: 'flex', justifyContent: "center", alignItems: "center", aspectRatio: "16/9" }} >
                {props.image ? <img src={props.image} alt={props.alt} style={{width: "50%"}} /> : <p style={{fontSize: '64px'}}>{props.text}</p>}
            </div>
        </Parallax>
    )
}

export default ProjectParallax