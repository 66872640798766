import React from 'react';
import { Parallax } from 'react-parallax';
import transition from '../../transition';
import ProjectTitle from '../ProjectTitle/ProjectTitle';
import ProjectInfo from '../ProjectInfo/ProjectInfo';
import '../Project.css';
import ProjectVideo from '../ProjectVideo/ProjectVideo';
import ProjectFeatures from '../ProjectFeatures/ProjectFeatures';
import ProjectGallery from '../ProjectGallery/ProjectGallery';

import Binti1 from '../../assets/images/projects/binti/binti1.png';
import Binti2 from '../../assets/images/projects/binti/binti2.png';
import Binti3 from '../../assets/images/projects/binti/binti3.png';
import Binti4 from '../../assets/images/projects/binti/binti4.png';
import Binti5 from '../../assets/images/projects/binti/binti5.png';
import Binti6 from '../../assets/images/projects/binti/binti6.png';
import Binti7 from '../../assets/images/projects/binti/binti7.png';
import Binti8 from '../../assets/images/projects/binti/binti8.png';
import Binti9 from '../../assets/images/projects/binti/binti9.png';
import Binti10 from '../../assets/images/projects/binti/binti10.png';
import Binti11 from '../../assets/images/projects/binti/binti11.png';
import Binti12 from '../../assets/images/projects/binti/binti12.png';
import Binti13 from '../../assets/images/projects/binti/binti13.png';
import Binti14 from '../../assets/images/projects/binti/binti14.png';
import Binti15 from '../../assets/images/projects/binti/binti15.png';
import Binti16 from '../../assets/images/projects/binti/binti16.png';
import BintiBanner from '../../assets/images/projects/binti/banner.png';
import ProjectNextButton from '../ProjectNextButton/ProjectNextButton';
import ProjectParallax from '../ProjectParallax/ProjectParallax';

const Binti = () => {
  const color = 'var(--binti)';
  const images1 = [
    { src: Binti16, alt: 'Binti Biz landing page mobile' },
    { src: Binti6, alt: 'Binti Biz landing page' },
    { src: Binti5, alt: "Create account screen" },
  ];

  const images2 = [
    { src: Binti15, alt: 'Create account screen mobile' },
    { src: Binti4, alt: 'Placement quiz where users answer questions to be put into their specific course' },
    { src: Binti11, alt: "Logged in user Dashboard" },
  ];

  const images3 = [
    { src: Binti13, alt: 'Mobile course dashboard' },
    { src: Binti9, alt: 'Resources page for course' },
    { src: Binti8, alt: "Community page for course" },
  ];

  const images4 = [
    { src: Binti12, alt: 'Mobile video lesson viewer' },
    { src: Binti3, alt: 'Course lessons' },
    { src: Binti2, alt: "Course lesson popup" },
  ];

  return (
    <div className='project binti'>
      <div className='projectWrapper'>
        <ProjectTitle title="Binti Biz" color={color} />
        <ProjectInfo
          date="January 2024"
          stack={["Figma", "Photoshop"]}
          buttons={[{ link: 'screenshots', words: 'View Images' }]}
          color={color}
        />
        <p>
          My journey with Binti Biz began when I was approached by{' '}
          <a href="https://www.3labs.us/" target='_blank' style={{ color }}>
            3labs
          </a>
          , a distinguished design and development firm based in Bloomington, Indiana. Tasked with building the prototype for BintiBiz, an innovative platform aimed at empowering female entrepreneurs in Africa, my role extended beyond mere design—it was about championing inclusivity and challenging prevailing stereotypes.
        </p>
        <p>
          At its core, Binti Biz is a visionary endeavor, offering a transformative online learning platform tailored for female entrepreneurs in Africa. It's a space where women can expand their business and investment knowledge through a comprehensive array of courses, mentoring, and coaching programs. However, our mission surpassed conventional boundaries; we aimed to defy stereotypes by embracing traditionally feminine elements, such as the color pink, while eschewing the superficial "girl boss" narrative.
        </p>
        <p>
          Influenced by a diverse range of design principles, our approach to BintiBiz aimed to create an engaging and accessible online learning platform. By employing vibrant color schemes and dynamic typography, we sought to captivate users' attention while ensuring inclusivity. BintiBiz serves as a gateway to empowerment, offering a nuanced understanding of entrepreneurship that transcends gender stereotypes.
        </p>
        <p className='project-bold-text'>
          The BintiBiz platform is currently under development and expected to launch in <span className='bold'>late 2024</span>.
        </p>
        <ProjectParallax background={BintiBanner} text="Binti Biz" />
        <ProjectFeatures
          featureList={[
            'Intuitive User Flow',
            'Personalized Quiz Assessment',
            'Dynamic Course Content',
            'Community Engagement Tools',
            'Progress Tracking Dashboard',
            'Mobile-Responsive Design',
            'Engagement Notifications',
          ]}
          color={color}
        />
        <h2 className='screenshots'>
          Screenshots<span style={{ color }}>.</span>
        </h2>
        <ProjectGallery images={images1} reverse={false} />
        <ProjectGallery images={images2} reverse={true} />
        <ProjectGallery images={images3} reverse={false} />
        <ProjectGallery images={images4} reverse={true} />
        <ProjectNextButton link='/projects/electricfox' color={color} />
      </div>
    </div>
  );
};

export default transition(Binti);
