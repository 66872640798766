import React from 'react';
import './ProjectsFilter.css'
import { useEffect, useState } from 'react';
import ProjectsCard from './ProjectsCard/ProjectsCard';
import ProjectsFilterFilter from './ProjectsFilterFilter/ProjectsFilterFilter';
import { motion, AnimatePresence } from 'framer-motion'
import CrimsonFlagsIcon from '../../../assets/images/projects/crimson-flags/crimsonflagsicon.png';
import MenuMakerIcon from '../../../assets/images/projects/menu-maker/menu-maker-icon.png';
import IGWSIcon from '../../../assets/images/projects/igws/icon.png'
import BintiIcon from '../../../assets/images/projects/binti/binti-icon.png'
import ElectricFoxIcon from '../../../assets/images/projects/electricfox/sundanceIcon.png'
import QFWellnessIcon from '../../../assets/images/projects/qf-wellness/square.png'

const ProjectsFilter = () => {
    const projectsList = [
        { title: "IGWS", desc: "The IGWS website, with a custom CMS, provides concise insights into geological and water-related research by the Indiana Geological and Water Survey.", link: "/projects/igws", img: IGWSIcon, double: true, featured: true, tags: ['JavaScript', 'React.js', 'SQL', "UI/UX", "Node.js"] },
        { title: "Menu Maker", desc: "A web application built to assist in the live updating of digital signage boards.", link: "/projects/menumaker", img: MenuMakerIcon, tags: ['PHP', "JavaScript", "SQL"] },


        { title: "QF Wellness", desc: "An online learning platform for functional wellness courses.", link: "/projects/qfwellness", img: QFWellnessIcon, tags: ["JavaScript", "SQL", "Node.js", "Next.js"] },


        
        { title: "Binti Biz", desc: "UX/UI design for an online learning platform for business owners.", link: "/projects/bintibiz", img: BintiIcon, tags: ["UI/UX"] },
        { title: "CrimsonFlags", desc: "An award winning roommate matcher built for Indiana University Students.", double: true, link: "/projects/crimsonflags", img: CrimsonFlagsIcon, tags: ['JavaScript', 'PHP', "SQL", "UI/UX"] },
        { title: "Electric Fox", desc: "A static website built for a mountainbiking/ hair salon company.", link: "/projects/electricfox", 
        img: ElectricFoxIcon, tags: ['JavaScript' ,"React.js", "UI/UX"] },

    ];
    const [originalFilter, setOriginalFilter] = useState([]);
    const [filtered, setFiltered] = useState([])

    useEffect(() => {
        setOriginalFilter(projectsList)
        setFiltered(projectsList)
    }, [])


    const [activeButton, setActiveButton] = useState("All")




    return (
        <div className='projects-filter'>
            <ProjectsFilterFilter originalFilter={originalFilter} setFiltered={setFiltered} activeButton={activeButton} setActiveButton={setActiveButton} />

            <motion.div layout={true} className='projects-filter-cards'>
                <AnimatePresence>
                    {filtered.map((project) => {
                        return (<ProjectsCard key={project.title} data={project} />)
                    })}
                </AnimatePresence>
            </motion.div>
        </div>
    )
}

export default ProjectsFilter