import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeNav, setActiveNav] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [showUnderline, setShowUnderline] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const threshold = 30;

      if (scrollTop > threshold) {
        setScrolled(true);
      } else if (scrollTop < threshold - 20) {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function isAtBottomOfPage(scrollPosition){
    return window.innerHeight + scrollPosition >= document.body.offsetHeight - 20;
  };
  

  useEffect(() => {
    const handleSectionChange = () => {
      const sections = document.querySelectorAll('section[id]');
      const scrollPosition = window.scrollY;
      const scrollDirection = scrollPosition > lastScrollTop ? 'down' : 'up';
      setLastScrollTop(scrollPosition);

        sections.forEach((section) => {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.clientHeight;
          const activationOffset = scrollDirection === 'up' ? 160 : 50;
          if(!isAtBottomOfPage(scrollPosition)){
          if (
            scrollPosition >= sectionTop - activationOffset &&
            scrollPosition <= sectionTop + sectionHeight
          ) {
            setActiveSection(section.getAttribute('id'));
          }
        } else {
          setActiveSection("contact")
        }
        });
    


    };

    window.addEventListener('scroll', handleSectionChange);

    return () => {
      window.removeEventListener('scroll', handleSectionChange);
    };
  }, [lastScrollTop]);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop + 1,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      const sectionId = location.hash.substring(1);
      if (sectionId) {
        setTimeout(() => scrollToSection(sectionId), 300); // Adjust the delay to match your transition duration
      }
      setShowUnderline(false);
      setTimeout(() => setShowUnderline(true), 3000); // Show underline after 1.5 seconds
    } else if (location.pathname === '/resume') {
      setActiveSection('resume-nav');
      setShowUnderline(true); // Show underline immediately for resume page
    } else {
      setShowUnderline(false); // Hide underline for other pages
    }
  }, [location]);

  useEffect(()=>{
    if(activeNav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

  },[activeNav])

  const handleNavigation = (e, path) => {
    setActiveNav(false)
    e.preventDefault();
    const sectionId = path.split('#')[1];
    if (location.pathname !== '/') {
      navigate(path, { replace: true });
      setTimeout(() => scrollToSection(sectionId), 1500); // Adjust the delay to match your transition duration
    } else {
      scrollToSection(sectionId);
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
    setActiveSection('home')
  };

  return (
    <nav className={scrolled ? 'navScrolled' : ''}>
      <div className='navContainer'>
        <div className='logo'>
          <Link to="/" onClick={handleLogoClick}>KF</Link>
        </div>
        <ul className={activeNav ? "navActive" : ""}>
          <li className='spot1'>
            <Link to="/#about" className={activeSection === 'about' ? 'active-nav' : ''} onClick={(e) => handleNavigation(e, '/#about')}>
              About
            </Link>
          </li>
          <li className='spot2'>
            <Link to="/#projects" className={activeSection === 'projects' ? 'active-nav' : ''} onClick={(e) => handleNavigation(e, '/#projects')}>
              Projects
            </Link>
          </li>
          <li className='spot3'>
            <Link to="/#services" className={activeSection === 'services' ? 'active-nav' : ''} onClick={(e) => handleNavigation(e, '/#services')}>
              Services
            </Link>
          </li>
          <li className='spot4'>
            <Link to="/resume" className={activeSection === 'resume-nav' ? 'active-nav' : ''} onClick={()=>setActiveNav(false)}>
              Resume
            </Link>
          </li>
          <li className='spot5'>
            <Link to="/#contact" className={activeSection === 'contact' ? 'active-nav' : ''} onClick={(e) => handleNavigation(e, '/#contact')}>
              Contact
            </Link>
          </li>
          {showUnderline && <div className={`animatedUnderline ${activeSection}`}></div>}
        </ul>
        <button onClick={()=> setActiveNav(!activeNav)}  className={activeNav ? 'navMobileToggleOff' : 'navMobileToggleOn'}>
            <span className='navCircle topLeft'></span>
            <span className='navCircle topMid'></span>
            <span className='navCircle topRight'></span>
            <span className='navCircle midLeft'></span>
            <span className='navCircle midMid'></span>
            <span className='navCircle midMid2'></span>
            <span className='navCircle midRight'></span>
            <span className='navCircle botLeft'></span>
            <span className='navCircle botMid'></span>
            <span className='navCircle botRight'></span>
        </button>
      </div>
    </nav>
  );
};

export default Navigation;
