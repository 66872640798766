import React, { useState, useRef, useEffect } from 'react';
import './Contact.css'; // Import your CSS file for styling
import cat from '../../assets/gif/cat.gif'; // Example GIF import


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    services: [],
    budget: '',
    message: ''
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleServiceToggle = (service) => {
    const services = formData.services.includes(service)
      ? formData.services.filter((s) => s !== service)
      : [...formData.services, service];
    setFormData({ ...formData, services });
  };

  const handleBudgetSelect = (budget) => {
    setFormData({ ...formData, budget });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://kevinfuller.me/send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        setIsFormSubmitted(true);
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while sending the email');
    }
  };

  const isServiceSelected = (service) => formData.services.includes(service);

  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = '0.8em';
    textarea.style.height = `calc(${textarea.scrollHeight}px - 1em)`;
    textarea.scrollTop = textarea.scrollHeight;
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [formData.message]);

  const [isInView, setIsInView] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const contactSection = document.getElementById('contact');
      if (!contactSection) return;

      const contactPosition = contactSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (contactPosition < windowHeight * 0.75) {
        setIsInView(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="contact">
      {isFormSubmitted ? (
        <div className="thank-you-message contact-form">
          <h2 className={`fade-in ${isInView ? 'active' : ''}`}>Thanks for<br/><span className='contact-italic'>reaching out!</span></h2>
          <p>I will get back to you soon!</p>
          <img src={cat} alt="cat typing" />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <h2 className={`fade-in ${isInView ? 'active' : ''}`}>
            <span className='contact-italic'>Have an idea?</span><br></br>
            I'd love to help.
          </h2>
          <div className="form-section">
            <label className={`fade-in ${isInView ? 'active' : ''}`}>I'm interested in...</label>
            <div className={`fade-in ${isInView ? 'active button-container' : 'button-container'}`}>
              <button
                type="button"
                className={isServiceSelected('site-from-scratch') ? 'selected' : ''}
                onClick={() => handleServiceToggle('site-from-scratch')}
              >
                Site from scratch
              </button>
              <button
                type="button"
                className={isServiceSelected('ux-ui-design') ? 'selected' : ''}
                onClick={() => handleServiceToggle('ux-ui-design')}
              >
                UX/UI design
              </button>
              <button
                type="button"
                className={isServiceSelected('branding') ? 'selected' : ''}
                onClick={() => handleServiceToggle('branding')}
              >
                Branding
              </button>
              <button
                type="button"
                className={isServiceSelected('development') ? 'selected' : ''}
                onClick={() => handleServiceToggle('development')}
              >
                Development
              </button>
              <button
                type="button"
                className={isServiceSelected('maintenance') ? 'selected' : ''}
                onClick={() => handleServiceToggle('maintenance')}
              >
                Maintenance
              </button>
              <button
                type="button"
                className={isServiceSelected('e-commerce') ? 'selected' : ''}
                onClick={() => handleServiceToggle('e-commerce')}
              >
                E-commerce
              </button>
              <button
                type="button"
                className={isServiceSelected('general-inquiry') ? 'selected' : ''}
                onClick={() => handleServiceToggle('general-inquiry')}
              >
                General Inquiry
              </button>
            </div>
          </div>

          <div className={`form-section animated-section ${formData.services.length > 0 ? 'active' : ''}`}>
            <label>Project Budget (USD)</label>
            <div className="button-container">
              <button
                type="button"
                className={formData.budget === '1000-2500' ? 'selected' : ''}
                onClick={() => handleBudgetSelect('1000-2500')}
              >
                1-2.5k
              </button>
              <button
                type="button"
                className={formData.budget === '2500-5000' ? 'selected' : ''}
                onClick={() => handleBudgetSelect('2500-5000')}
              >
                2.5-5k
              </button>
              <button
                type="button"
                className={formData.budget === '5000-7500' ? 'selected' : ''}
                onClick={() => handleBudgetSelect('5000-7500')}
              >
                5-7.5k
              </button>
              <button
                type="button"
                className={formData.budget === '7500-10000' ? 'selected' : ''}
                onClick={() => handleBudgetSelect('7500-10000')}
              >
                7.5-10k
              </button>
              <button
                type="button"
                className={formData.budget === '10000+' ? 'selected' : ''}
                onClick={() => handleBudgetSelect('10000+')}
              >
                &gt;10k
              </button>
            </div>
          </div>
          <div className={`form-section animated-section ${formData.budget ? 'active' : ''}`}>
            <div>
              <label htmlFor="name">Your name:</label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label htmlFor="email">Your email:</label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label htmlFor="message">Tell me about your project:</label>
              <textarea
                name="message"
                id="message"
                value={formData.message}
                onChange={handleChange}
                ref={textareaRef}
                required
                style={{
                  overflow: 'hidden',
                  resize: 'none',
                  verticalAlign: 'baseline',
                }}
              ></textarea>
            </div>
            <br />
            <button className="contact-submit" type="submit">Send Request</button>
          </div>
        </form>
      )}
    </section>
  );
};

export default ContactForm;
