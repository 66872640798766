import React from 'react';
import { Document, Page, Text, View, PDFViewer } from '@react-pdf/renderer';
import { FaGlobe, FaEnvelope, FaPhone, FaLinkedin, FaGithub, FaMapMarkerAlt } from 'react-icons/fa';
import './Resume.css';
import transition from '../../transition';
import { Link } from 'react-router-dom';
import resumeDownload from '../../assets/files/Kevin_Fuller_Resume.pdf'

const Resume = () => {
  const handleDownload = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = resumeDownload;

    // Set the filename for the download
    link.download = 'Kevin_Fuller_Resume.pdf';

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  };


  return (
    <main className='resumePage'>
      <div className='resumeButtonsWrapper'>

        <Link to="/" className='backButton'>Go back</Link>

        <div className='print-buttons-wrapper'>
          <button
            onClick={() => window.print()}
            class="printButton"
          ><i class="fas fa-print mr-3" />Print</button>
      <button onClick={handleDownload} className="downloadButton">
            <i className="fas fa-download mr-3" />Download
          </button>
        </div>

      </div>

      <div className='resumeWrapper'>
        <div className='resume'>
          <section className='mt-none'>
            <h1>Kevin Fuller</h1>
            <div className='resumeLinksWrapper'>
              <ul>
                <li><FaGlobe /> <a href="https://kevinfuller.me">kevinfuller.me</a></li>
                <li><FaEnvelope /> <a href="mailto:kgfuller@yahoo.com">kgfuller@yahoo.com</a></li>
                <li><FaPhone /> <a href="tel:+812-381-1593">(812) 381-1593</a></li>
                <li><FaLinkedin /> <a href="https://www.linkedin.com/in/kevin-fuller1/">linkedin.com/in/kevin-fuller1/</a></li>
                <li><FaGithub /> <a href="https://github.com/Kevin-Fuller">github.com/Kevin-Fuller</a></li>
                <li><FaMapMarkerAlt /> Bloomington, IN, United States</li>
              </ul>
            </div>
          </section>

          <section className='resumeEducation'>
            <h2>Professional Summary</h2>
            <div className='educationWrapper'>
              <p>Fullstack Web Developer with over 6 years of experience specializing in both front-end and back-end development. Proficient in JavaScript, React, Node.js, and various database technologies. Proven ability in upgrading legacy systems, developing custom CMS solutions, and optimizing business processes. Strong background in UI/UX design with a keen eye for creating intuitive and engaging user interfaces. Adept at collaborating with cross-functional teams to deliver impactful web applications.</p>
            </div>
          </section>

          <section>
            <h2>Technical Skills</h2>
            <div className='technicalSkillsGrid'>
              <h3>Languages:</h3>
              <p>JavaScript (ES6), HTML, CSS, Python, TypeScript, SQL, ColdFusion, PHP</p>
              <h3>Frontend:</h3>
              <p>React, Tailwind, Bootstrap, Figma</p>
              <h3>Backend:</h3>
              <p>Node.js, Express</p>
              <h3>Database:</h3>
              <p>MySQL, Postgres, Firebase</p>
              <h3>Other:</h3>
              <p>Git, NPM, Postman, Adobe Photoshop, Adobe Illustrator, Adobe After Effects, ArcGIS</p>
            </div>
          </section>

          <section>
            <h2>Work Experience</h2>
            <ul className='workExperienceItem'>
              <li className='resumeWork'>
                <div className='resumeWorkWrapper'>
                  <h3>Indiana Geologic and Water Survey</h3>
                  <em>Fullstack Web Developer</em>
                  <p className='ml-auto'>Bloomington, IN</p>
                  <p>July. 2022 - Present</p>
                </div>
                <ul className='workExperienceInfo'>
                  <li>Upgraded the company website from ColdFusion to a React frontend and Node.js backend.</li>
                  <li>Built a custom CMS tailored to the unique needs of the IGWS.</li>
                  <li>Developed a company standard for migrating from development to production environments.</li>
                  <li>Led biweekly meetings on website status and future goals.</li>
                  <li>Upgraded the structure of legacy MySQL databases.</li>
                </ul>
              </li>
              <li>
                <div className='resumeWorkWrapper'>
                  <h3>BuffaLouie's</h3>
                  <em>IT Manager</em>
                  <p className='ml-auto'>Bloomington, IN</p>
                  <p>September. 2018 – July 2022</p>
                </div>
                <ul className='workExperienceInfo'>
                  <li>Managed and maintained all technical aspects of the establishment, including the company app, website, Linux signage machines, and databases.</li>
                  <li>Developed an in-house web application using PHP, MySQL, JavaScript, and HTML/CSS to live-update digital signage, now used by 5 businesses around Bloomington, IN.
                  </li>   
                  <li>Collaborated with developers to deploy a mobile app, resulting in a 270% increase in online sales.</li>
                  <li>Documented and analyzed business processes to identify improvement opportunities.
                  </li>
                  <li>Designed promotional images.
                  </li>
                </ul>
              </li>
              <li>
                <div className='resumeWorkWrapper'>
                  <h3>Freelance Web Developer</h3>
                  <em>Fullstack Web Developer</em>
                  <p className='ml-auto'>Bloomington, IN</p>
                  <p>January 2018 - Present</p>
                </div>
                <ul className='workExperienceInfo'>
                  <li>Developed, optimized, and maintained websites and applications using HTML/CSS, PHP, JavaScript, React.js, MySQL, jQuery, and various APIs/Content Management Systems.
                  </li>
                  <li>Created design mockups using Figma, Photoshop, and Illustrator for cross-browser and device compatibility.</li>
                  <li>Diagnosed, troubleshooted, and resolved issues with client websites, servers, and third-party integrations.</li>
                </ul>
              </li>
              <li>
                <div className='resumeWorkWrapper'>
                  <h3>Gables Bagels</h3>
                  <em>Web Developer & Graphic Designer</em>
                  <p className='ml-auto'>Bloomington, IN</p>
                  <p>August 2021 - December 2021</p>
                </div>
                <ul className='workExperienceInfo'>
                  <li>Designed the company logo using Photoshop and Illustrator.
                  </li>
                  <li>Upgraded the company back-end web database.</li>
                </ul>
              </li>
            </ul>
          </section>

          <section className='resumeEducation'>
            <h2>Education</h2>
            <div className='educationWrapper'>
              <h3>Indiana University, Bloomington</h3>
              <p>May 2022</p>
            </div>
            <div className='educationContent'>
              <p>Luddy School of Informatics, Computing, and Engineering</p>
              <p>Bachelor of Science in Informatics <br />Human-Centered Computing Cognate, Web Design Minor</p>
            </div>
            <ul>
              <li>Academic Dean's List</li>
              <li><a href="">Top Project in Informatics Capstone Course</a></li>
            </ul>
          </section>
        </div>
      </div>
    </main>
  );
};

export default transition(Resume);
