import React from 'react'
import transition from '../../transition'
import ProjectTitle from '../ProjectTitle/ProjectTitle'
import ProjectInfo from '../ProjectInfo/ProjectInfo'
import '../Project.css'

import electricFox1 from '../../assets/images/projects/electricfox/sundance01.png';
import electricFox2 from '../../assets/images/projects/electricfox/sundance02.jpg';
import electricFox3 from '../../assets/images/projects/electricfox/sundance03.png';
import electricFox4 from '../../assets/images/projects/electricfox/sundance04.jpg';
import electricFox5 from '../../assets/images/projects/electricfox/sundance05.jpg';

import electricFoxOld1 from '../../assets/images/projects/electricfox/sundanceOld01.jpg';
import electricFoxOld2 from '../../assets/images/projects/electricfox/sundanceOld02.jpg';
import electricFoxOld3 from '../../assets/images/projects/electricfox/sundanceOld03.jpg';
import electricFoxOld4 from '../../assets/images/projects/electricfox/sundanceOld04.jpg';
import electricFoxOld5 from '../../assets/images/projects/electricfox/sundanceOld05.jpg';

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import ProjectNextButton from '../ProjectNextButton/ProjectNextButton'

const ElectricFox = () => {
    const color = "var(--electricfox)"


    return (
        <div className='project electricfox'>
            <div className='projectWrapper'>
                <ProjectTitle title="Electric Fox at Sundance" color={color} />
                <ProjectInfo date="March - May 2022"
                    stack={["React.js", "Javascript", "Firebase", "HTML/CSS", "Git/Github"]}
                    buttons={[{ link: "https://electricfoxatsundance.com/biking/", words: "View Site" }]}
                    color={color}
                />
                <p>Electric Fox at Sundance is a company founded by Sally Collins, an Indiana entrepreneur which has two branches--Sundance MTB Skill Clinic and the Electric Fox Hair Salon. The challenge of this website was to find a way to merge two very different topics (mountain biking and hair) into a design which feels cohesive but still articulates what makes each branch of the brand unique.</p>
                <p>Sally originally came to me with a problem, she had two very outdated, non-mobile friendly wordpress websites which did not properly articulate what she felt was her company's brand identity. She wanted something which not only felt professional, but also drove home her unique and fun-loving personality under a single domain.</p>
                <p>After brainstorming, we decided on going for something we did not see in any other mountainbiking or hair salons: a focus on vibrant colors and unique user interaction, in a playful and unique spin on brand identity. The website offers many unique design decisions including vibrant colors, parallax animations, and a user friendly approach towards digestible content.</p>
                <p className='project-bold-text'>Electric Fox at Sundance is used by <span className='bold'>thousands of mountainbikers</span> around the state of indiana and serves as a convienient landing page for Sally's hair clients.</p>
                <h2 className='screenshots'>Before & After<span style={{ color: color }}>.</span></h2>
                
                <div className='slidersContainer'>
                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={electricFoxOld1} srcSet={electricFoxOld1} alt="Electric Fox at Sundance Old Homescreen" />}
                    itemTwo={<ReactCompareSliderImage src={electricFox1} srcSet={electricFox1} alt="Electric Fox at Sundance New Homescreen" />}
                />

                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={electricFoxOld2} srcSet={electricFoxOld2} alt="Electric Fox at Sundance Old Programs Page" />}
                    itemTwo={<ReactCompareSliderImage src={electricFox2} srcSet={electricFox2} alt="Electric Fox at Sundance New Programs Page" />}
                />

                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={electricFoxOld3} srcSet={electricFoxOld3} alt="Electric Fox at Sundance Old About Page" />}
                    itemTwo={<ReactCompareSliderImage src={electricFox3} srcSet={electricFox3} alt="Electric Fox at Sundance New About Page" />}
                />

                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={electricFoxOld4} srcSet={electricFoxOld4} alt="Electric Fox at Sundance Old 'Meet Sally' Page" />}
                    itemTwo={<ReactCompareSliderImage src={electricFox4} srcSet={electricFox4} alt="Electric Fox at Sundance New 'Meet Sally' Page" />}
                />

                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={electricFoxOld5} srcSet={electricFoxOld5} alt="Electric Fox at Sundance Old Media Page" />}
                    itemTwo={<ReactCompareSliderImage src={electricFox5} srcSet={electricFox5} alt="Electric Fox at Sundance New Media Page" />}
                />
                </div>

                <ProjectNextButton link="/projects/igws" color={color}/>

            </div>
        </div>
    )
}

export default transition(ElectricFox)