import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import Projects from '../Projects/Projects';
import About from '../About/About';
import Services from '../Services/Services';
import transition from '../../transition';
import ThreeMonitor from '../../components/ThreeMonitor/ThreeMonitor';
import Contact from '../../components/Contact/Contact';
import ScrollingSection from '../../components/ScrollingSection/ScrollingSection';
import TechStack from '../../components/TechStack/TechStack';

const Home = (props) => {

  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Header scrollPosition={scrollPosition}/>
      <About />
      <Projects />
      <ScrollingSection />
      <Services />
      <TechStack />
      <Contact />

    </div>
  );
};

export default transition(Home);
