import React from 'react'
import './ProjectFeatures.css'

const ProjectFeatures = (props) => {
  return (
    <div className='projectFeatures'>
      <h2>Notable Features<span style={{color: props.color}}>.</span></h2>
      <ul>
        {props.featureList.map((item, index)=>{
          return(<li key={index}>{item}</li>)
        })}
      </ul>

    </div>
  )
}

export default ProjectFeatures