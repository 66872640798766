import React from 'react'
import transition from '../../transition'
import ProjectTitle from '../ProjectTitle/ProjectTitle'
import ProjectInfo from '../ProjectInfo/ProjectInfo'
import '../Project.css'

import ProjectNextButton from '../ProjectNextButton/ProjectNextButton'
import ProjectFeatures from '../ProjectFeatures/ProjectFeatures'
import QFW1 from '../../assets/images/projects/qf-wellness/mobileQFW01.png';
import QFW2 from '../../assets/images/projects/qf-wellness/mobileQFW02.png';
import QFW3 from '../../assets/images/projects/qf-wellness/desktopQFW01.png'
import QFW4 from '../../assets/images/projects/qf-wellness/desktopQFW02.png';
import QFW5 from '../../assets/images/projects/qf-wellness/desktopQFW03.png';
import QFW6 from '../../assets/images/projects/qf-wellness/desktopQFW04.png';
import banner from '../../assets/images/projects/qf-wellness/banner.png';
import logo from '../../assets/svg/quintana.svg';
import ProjectGallery from '../ProjectGallery/ProjectGallery'
import ProjectParallax from '../ProjectParallax/ProjectParallax'

const images1 = [
    { src: QFW2, alt: 'Quintana Functional Wellness homepage mobile' },
    { src: QFW6, alt: 'Quintana Functional Wellness homepage' },
    { src: QFW5, alt: "Media page" },
];

const images2 = [
    { src: QFW1, alt: 'Mobile navigation' },
    { src: QFW3, alt: 'CMS page featuring image' },
    { src: QFW4, alt: "Testimonials section" },
];

const QFWellness = () => {
    const color = "var(--qfwellness)"


    return (
        <div className='project qfwellness'>
            <div className='projectWrapper'>
                <ProjectTitle title="Quintana Functional Wellness" color={color} />
                <ProjectInfo date="June - July 2024"
                    stack={["React.js", "Next.js", "Javascript", "MySQL", "HTML/CSS", "Git/Github"]}
                    buttons={[{ link: "https://quintanafunctionalwellness.com/", words: "View Site" }]}
                    color={color}
                />
                <p>Quintana Functional Wellness is dedicated to supporting women managing Hashimoto's thyroiditis through a holistic approach championed by Tara Quintana, a renowned figure in functional medicine. At the heart of Tara's mission is the creation of an inclusive online platform where individuals can access vital resources and educational materials, including her transformative Hashimoto's recovery blueprint program—a 12-week journey designed to help anyone with Hashimoto's find a viable plan to address the root cause of their symptoms and reclaim their life. As the developer behind this initiative, I collaborated closely with Tara to build a robust solution tailored to her specific needs.</p>
                <p>
                    My role encompassed crafting a user-friendly interface using Next.js, ensuring seamless navigation and accessibility across all devices. The platform boasts a login system and a customizable CMS, enabling Tara to effortlessly manage and update content. Integrated Stripe payment functionality facilitates secure transactions, while comprehensive analytics tools empower Tara to track patient progress and optimize her treatment strategies effectively.
                </p>
                <p>Beyond technical implementation, my focus was on enhancing user experience and fostering community engagement. By prioritizing intuitive design and reliable functionality, we've created more than just a learning portal; Quintana Functional Wellness serves as a beacon of empowerment and support for women navigating Hashimoto's. Together, we've established a dynamic platform that not only educates but also inspires individuals to take charge of their health, reflecting Tara's unwavering commitment to improving lives.</p>
                <p className='project-bold-text'>Tara Quintana has helped <span class='bold'>hundreds of women</span> across the nation see improvements in their health and become more optimistic about their future.</p>
                <ProjectParallax background={banner} image={logo} />

                <ProjectFeatures featureList={["Google, Facebook, & Apple Login", "Custom CMS", "Create Online Course", "View Online Courses", "Stripe Payment Integration", "Progress Tracking", "Analytics Tools"]} color={color} />

                <h2 className='screenshots'>Screenshots<span style={{ color: color }}>.</span></h2>
                <ProjectGallery images={images1} reverse={false} />
                <ProjectGallery images={images2} reverse={true} />

                <ProjectNextButton link="/projects/bintibiz" color={color} />

            </div>
        </div>
    )
}

export default transition(QFWellness)