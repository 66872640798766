import React from 'react'
import './ProjectInfo.css'

const ProjectInfo = (props) => {
  return (
    <div className='projectInfo'>
        <ul>
            <li className='projectInfoTitle'>Date:</li>
            <li>{props.date}</li>
        </ul>
        <ul>
            <li className='projectInfoTitle'>Tech Stack Used:</li>
            {props.stack.map((item, index)=>{
                return(<li key={index}>{item}</li>)
            })}
        </ul>
        <ul className='siteButtons'>
            {props.buttons.map((button, index)=>{
                if(button.link === "screenshots"){
                    return(<li key={index}><a href="#screenshots">{button.words}</a></li>)
                }else {
                    return(<li key={index}><a target="_blank" href={button.link}>{button.words}</a></li>)
                }
            })}
        </ul>
    </div>
  )
}

export default ProjectInfo