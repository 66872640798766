import * as React from "react"

const EarthFront = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="earthFront"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={1080}
    height={1080}
    viewBox="109.843 109.541 1080 1080"
    enableBackground="new 109.843 109.541 1080 1080"
    xmlSpace="preserve"
    {...props}
  >
    <defs>
      <path id="a" d="M109.843 61.541h1080v1080h-1080z" />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <g clipPath="url(#b)">
      <defs>
        <path id="c" d="M109.843 61.541h1080v1080h-1080z" />
      </defs>
      <clipPath id="d">
        <use xlinkHref="#c" overflow="visible" />
      </clipPath>
      <g opacity={0.17} clipPath="url(#d)">
        <defs>
          <path id="E" d="M303.221 339.396h407.814v407.806H303.221z" />
        </defs>
        <clipPath id="F">
          <use xlinkHref="#E" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#F)"
          fill="#FFF"
          d="M698.395 613.795a183.277 183.277 0 0 1-2.108 5.465c-20.092 50.125-58.063 88.057-103.8 109.147a203.347 203.347 0 0 1-9.638 4.151c-2.308.927-4.632 1.81-6.969 2.648-33.878 12.159-71.114 15.442-108.018 8.149a182.249 182.249 0 0 1-4.201-.878 204.86 204.86 0 0 1-27.019-7.909c-54.712-20.165-95.627-61.34-116.93-110.994-19.795-46.217-22.613-99.835-3.843-150.764a209.38 209.38 0 0 1 5.752-14.03 202.537 202.537 0 0 1 6.29-12.557c15.943-29.629 38.587-54.014 65.282-71.927a164.027 164.027 0 0 1 9.136-5.855 204.453 204.453 0 0 1 31.173-15.232c26.719-10.361 55.678-15.14 85.013-13.499 3.542.186 7.037.5 10.585.927l.043.016c6.797.749 13.585 1.789 20.359 3.261a181.28 181.28 0 0 1 13.79 3.471c4.823 1.34 9.566 2.894 14.335 4.652 50.948 18.777 89.942 55.772 112.233 100.9a203.587 203.587 0 0 1 12.029 30.158c12.821 41.307 12.618 86.983-3.494 130.7"
        />
      </g>
    </g>
    <g clipPath="url(#b)">
      <defs>
        <path
          id="G"
          d="M617.144 258.409c-9.778.808-19.286 1.902-28.781 3.471 97.863-.162 187.401 42.131 249.371 110.587 47.829 52.826 79.232 121.187 85.529 197.566 4.912 59.253-5.897 116.092-28.909 166.557a317.298 317.298 0 0 1-19.308 36.343c-.387.796-.856 1.561-1.327 2.326a367.61 367.61 0 0 1-14.956 21.989c-.112.052-.175.218-.317.353-.029.082-.173.218-.235.383a244.895 244.895 0 0 1-10.071 12.809c-.028.082-.028.082-.143.134a198.339 198.339 0 0 1-6.973 8.225c-1.393 1.739-2.919 3.336-4.395 5.046-5.768 6.227-11.671 12.312-17.927 18.078-11.575 11.032-24.042 21.175-37.204 30.402-47.158 33.212-103.586 54.652-165.355 59.765-95.656 7.922-185.399-25.154-251.749-84.524-55.315-49.578-94.449-117.597-107.249-195.433.116 9.616.564 19.355 1.371 29.133 8.578 104.063 57.068 195.336 129.107 259.899 77.403 69.358 182.271 108.009 294.103 98.727 101.157-8.334 190.236-54.442 254.479-123.313 3.863-4.114 7.644-8.26 11.237-12.661 65.713-76.682 101.875-178.617 92.911-287.234-7.341-89.341-44.099-169.155-100.009-230.872-72.134-79.801-176.388-129.117-290.355-129.116a396.996 396.996 0 0 0-32.845 1.36"
        />
      </defs>
      <clipPath id="I">
        <use xlinkHref="#G" overflow="visible" />
      </clipPath>
      <defs>
        <filter
          id="H"
          filterUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={1306.462}
          height={1306.57}
        >
          <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={1306.462}
        height={1306.57}
        id="K"
      >
        <g filter="url(#H)">
          <radialGradient
            id="J"
            cx={109.791}
            cy={1189.529}
            r={1}
            gradientTransform="scale(386.0829 -386.0829) rotate(-20.232 -3282.623 896.807)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={0}
              style={{
                stopColor: "#4d4d4d",
              }}
            />
            <stop
              offset={0.195}
              style={{
                stopColor: "gray",
              }}
            />
            <stop
              offset={0.618}
              style={{
                stopColor: "gray",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#4d4d4d",
              }}
            />
          </radialGradient>
          <path
            clipPath="url(#I)"
            fill="url(#J)"
            d="m351.882 0 954.58 351.819L954.58 1306.57 0 954.751z"
          />
        </g>
      </mask>
      <radialGradient
        id="L"
        cx={109.791}
        cy={1189.529}
        r={1}
        gradientTransform="scale(386.0829 -386.0829) rotate(-20.232 -3282.623 896.807)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#181d88",
          }}
        />
        <stop
          offset={0.195}
          style={{
            stopColor: "#181d8d",
          }}
        />
        <stop
          offset={0.618}
          style={{
            stopColor: "#181d8d",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#181d8d",
          }}
        />
      </radialGradient>
      <path
        clipPath="url(#I)"
        mask="url(#K)"
        fill="url(#L)"
        d="m351.882 0 954.58 351.819L954.58 1306.57 0 954.751z"
      />
    </g>
    <g clipPath="url(#b)">
      <defs>
        <path id="M" d="M109.843 113.541h1080v1080h-1080z" />
      </defs>
      <clipPath id="N">
        <use xlinkHref="#M" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#N)"
        fill="none"
        stroke="#0636CC"
        strokeWidth={32}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M637.811 1041.547c224.146 0 405.522-181.376 405.522-405.522"
      />
      <path
        clipPath="url(#N)"
        fill="none"
        stroke="#00D7FF"
        strokeWidth={32}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M655.875 251.341c-224.146 0-405.522 181.376-405.522 405.522"
      />
    </g>
  </svg>
)

export default EarthFront