import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './ProjectGallery.css';

const ProjectGallery = (props) => {
  const reverse = props.reverse;
  const images = props.images;
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (index) => {
    setSelectedImage(index);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const closeModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = ''; // Enable scrolling
  };

  useEffect(() => {
    return () => {
      // Cleanup function to enable scrolling when component unmounts
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className={reverse ? `project-gallery reverse` : 'project-gallery'} id="screenshots">
      <div className='slim image-container'>
        <motion.img
          src={images[0].src}
          onClick={() => openModal(0)}
          alt={images[0].alt}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.3 }}
        />
      </div>
      <div className='galleryColumn'>
        <div className='wide image-container'>
          <motion.img
            src={images[1].src}
            onClick={() => openModal(1)}
            alt={images[1].alt}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          />
        </div>
        <div className='wide image-container'>
          <motion.img
            src={images[2].src}
            onClick={() => openModal(2)}
            alt={images[2].alt}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          />
        </div>
      </div>

      <AnimatePresence>
        {selectedImage !== null && (
          <motion.div
            className='modal'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
            transition={{ duration: 0.3 }}
          >
            <motion.img
              key={selectedImage}
              src={images[selectedImage].src}
              alt={images[selectedImage].alt}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            />
            <p>{images[selectedImage].alt}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectGallery;
