import React, { useEffect, useRef, useState } from 'react';
import './ProjectsCard.css';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const useIntersectionObserver = (setIsVisible) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        }
      },
      { root: null, rootMargin: '0px 0px -100px 0px', threshold: 0.1 } // Adjust the threshold and rootMargin
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [setIsVisible]);

  return ref;
};

function ProjectsCard({ data }) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useIntersectionObserver(setIsVisible);
  const text = 'Featured - Featured - ';

  const renderRotatedText = () => {
    const characters = text.split('');

    return characters.map((char, index) => (
      <span className="rotateLetter" key={index} style={{ transform: `rotate(${index * (360 / characters.length)}deg)` }}>
        {char}
      </span>
    ));
  };

  return (
    <motion.div
      ref={ref}
      layout
      animate={{ opacity: isVisible ? 1 : 0 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className={`${data.double ? data.featured ? 'projectCard featured double' : 'projectCard double' : "projectCard"} ${isVisible ? 'visible' : ''}`}
      key={data.key}
    >
      <Link to={data.link}>
        {data.featured ? <div className='featuredCircle'>
          <p className="featured-circle-text">
            {renderRotatedText()}
          </p>
          <span className='starCircle'>&#x2605;</span>
        </div> : <></>}
        <div className='projectCardText'>
          <h3>{data.title}</h3>
          <p>{data.desc}</p>
        </div>
        <img src={data.img} alt={`${data.title} icon`} />
      </Link>
    </motion.div>
  );
}

export default ProjectsCard;
