import React, { useEffect, useRef, useState } from 'react';
import './About.css';
import Cube from './Cube/Cube';
import AbstractArtShader from './AbstractArtShader/AbstractArtShader';
import cat from '../../assets/gif/cat.gif'; // Example GIF import
import dog from '../../assets/gif/dog.gif'; // Example GIF import
import website from '../../assets/gif/website.gif'; // Example GIF import
import graduate from '../../assets/gif/graduate.gif'; // Example GIF import
import government from '../../assets/gif/government.gif'; // Example GIF import
import business from '../../assets/gif/business.gif'; // Example GIF import
import enterprise from '../../assets/gif/enterprise.gif'; // Example GIF import


const About = () => {
  const canvasRef = useRef(null);
  const aboutTextRef = useRef(null);
  const [hasScrolledInView, setHasScrolledInView] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showGif, setShowGif] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setHasScrolledInView(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
    observer.observe(aboutTextRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasScrolledInView]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    setShowGif(true);
  };

  const handleMouseLeave = () => {
    setShowGif(false);
    setHoveredIndex(null);
  };

  const currentYear = new Date().getFullYear();
  const startYear = 2018;
  const yearsExperience = currentYear - startYear;

  // Map of indices to GIFs
  const gifMap = {
    19: dog,
    20: dog,    
    21: cat,  
    22: cat,  

    25: website,
    26: website, 
    27: website, 
    28: website, 
    29: website, 
    30: website, 
    32: website, 
    12: graduate,
    13: government,
    14: business,
    15: business,
    17: enterprise,
  };

  return (
    <section id="about">
      <div className='aboutContentWrapper'>
        <AbstractArtShader />
        <div className={`aboutText ${hasScrolledInView ? 'active' : ''}`} ref={aboutTextRef}>
          <h2><span className='aboutWord projectTextHeadingFancy'>About</span> <span className='aboutWord' style={{ transitionDelay: '60ms' }}>Me</span></h2>
          <p>
            {`Greetings, I'm Kevin Fuller—an accomplished Full Stack Web Developer with over ${yearsExperience} years of hands-on experience. My expertise spans UI/UX Design, front/back-end development, and a fervent passion for pushing creative boundaries.`.split(" ").map((word, index) => {
              const boldWords = [19, 20, 21, 22, 25, 26, 27, 28, 29, 30, 32, 70, 71, 72, 73, 75, 76];

              const wordWithSpace = word + " ";

              if (boldWords.includes(index)) {
                return (
                  <span className='bold' key={index}>
                    <span
                      className='aboutWord'
                      style={{ transitionDelay: `${index * 20 + 100}ms` }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {wordWithSpace}
                    </span>
                  </span>
                );
              } else {
                return (
                  <span key={index} className='aboutWord' style={{ transitionDelay: `${index * 20 + 100}ms` }}>
                    {wordWithSpace}
                  </span>
                );
              }
            })}
          </p>

          <p>
            {"Specializing in unique websites that reflect your brand's identity, my experience spans education, government, small business, and enterprise sectors. Each project is designed to stand out from cookie-cutter designs and make the web more fun.".split(" ").map((word, index) => {
              const boldWords = [12, 13, 14, 15, 17];

              const wordWithSpace = word + " ";

              if (boldWords.includes(index)) {
                return (
                  <span className='bold' key={index}>
                    <span
                      className='aboutWord'
                      style={{ transitionDelay: `${index * 20 + 45 * 20 + 100}ms` }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {wordWithSpace}
                    </span>
                  </span>
                );
              } else {
                return (
                  <span key={index} className='aboutWord' style={{ transitionDelay: `${index * 20 + 45 * 20 + 100}ms` }}>
                    {wordWithSpace}
                  </span>
                );
              }
            })}
          </p>
        </div>

        {showGif && hoveredIndex !== null && (
          <img
            src={gifMap[hoveredIndex]}
            alt="hover gif"
            className="hoverGif"
            style={{
              position: 'fixed',
              zIndex: 2,
              top: mousePosition.y,
              left: mousePosition.x,
              height: '130px',
              transform: 'translate(-0%, -10%)',
              pointerEvents: 'none',
              cursor: 'none'
            }}
          />
        )}
      </div>
      <div className='triangle'></div>
    </section>
  );
};

export default About;
