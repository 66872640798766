import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './ProjectNextButton.css'

const ProjectNextButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Link to={props.link} className='projectNextButton'
        style={{
            color: props.color,
            border: `3px solid ${props.color}`,
            color: isHovered ? 'white' : props.color,
            backgroundColor: isHovered ?  props.color : '#ffffff00',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    >
        Next Project
    </Link>
  )
}

export default ProjectNextButton