import React, { useEffect, useRef, useState } from 'react';
import './ProjectsFilterFilter.css';

const ProjectsFilterFilter = ({ originalFilter, setFiltered, activeButton, setActiveButton }) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (activeButton === 'All') {
      setFiltered(originalFilter);
      return;
    }

    const filtered = originalFilter.filter((project) => project.tags.includes(activeButton));
    setFiltered(filtered);
  }, [activeButton, originalFilter, setFiltered]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(() => setIsVisible(true), 100); // Delay before setting visibility
        }
      },
      { root: null, threshold: 0.1 } // Adjust the threshold if necessary
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Extract unique tags from all projects
  const allTags = ['All', ...new Set(originalFilter.flatMap((project) => project.tags))];

  return (
    <div ref={containerRef} className='filter-container'>
      {/* Generate buttons dynamically with delayed visibility */}
      {allTags.map((tag, index) => (
        <button
          key={tag}
          className={`${activeButton === tag ? 'active' : ''} ${isVisible ? 'visible' : ''}`}
          onClick={() => setActiveButton(tag)}
          style={{ transitionDelay: `${index * 100 + 100}ms` }} // Adjust delay between buttons
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

export default ProjectsFilterFilter;
