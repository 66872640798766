import React, { useState, useEffect, useRef } from 'react';
import './Projects.css';
import ProjectsFilter from './ProjectsFilter/ProjectsFilter';

const Projects = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight - 150) {
          setIsVisible(true);
          window.removeEventListener('scroll', handleScroll);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Initial check in case the element is already in view
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="projects" ref={sectionRef} className={isVisible ? 'scrolled-into-view' : ''}>
      <div className="projectsWrapper">
        <div className="projectsText">
          <h2><span className='projectTextHeading projectTextHeadingFancy' style={{transitionDelay: '0s'}}>Featured</span><br /><span className='projectTextHeading ' style={{transitionDelay: '0.2s'}}>Work</span></h2>
          <p className='white'>
          <span className='projectTextWord' style={{transitionDelay: '0.6s'}}>Here </span>
          <span className='projectTextWord' style={{transitionDelay: '0.65s'}}>are </span>
          <span className='projectTextWord' style={{transitionDelay: '0.7s'}}>some </span>
          <span className='projectTextWord' style={{transitionDelay: '0.75s'}}>of </span>
          <span className='projectTextWord' style={{transitionDelay: '0.8s'}}>my </span>
          <span className='projectTextWord' style={{transitionDelay: '0.85s'}}>favorite </span>
          <span className='projectTextWord' style={{transitionDelay: '0.9s'}}>
            <span className='bold'>recent </span>
          </span>
          <span className='projectTextWord' style={{transitionDelay: '0.95s'}}>
            <span className='bold'>projects</span>
          </span>
          
          </p>
        </div>
        <ProjectsFilter />
      </div>
    </section>
  );
};

export default Projects;
